div
{
// position: relative;
}

body
{
	font-size: 14px;
	-webkit-font-smoothing: antialiased;
	color: $colour-base-text;
	background: $colour-body;

	min-width: 320px;


	@include font-regular;
}


a
{
	text-decoration: none;
	transition: color 200ms;
	color: $colour-dark-grey;



	&:active{
		text-decoration: none;
	}


	&:hover{
		color: $colour-accent-blue;
		cursor: pointer;
		text-decoration: none;
	}
}

.dark-grey
{
// color: $colour-dark-grey;
}

b
{
	font-weight: 500;
}
img{
	width: 100%;
}
p{
	line-height: 1.6em;
	margin-bottom: 20px;
}
strong{
	font-weight: bold;
}

blockquote{
	margin-left: 30px;
}