header.header{
	.wrapper{
		margin: 0 auto;
		max-width: $wrapper-width;
		@include container-section;
		#contact-header{
			margin-top: $spacing-default-vertical-margin;

			@media screen and (max-width: 1200px){
				padding-right: 25px;
				padding-left: 25px;
			}

			@media screen and (max-width: 500px){
				padding-right: 5px;
				padding-left: 5px;

				.mobile-hide{
					display: none;
				}


			}

			#co-name{
				font-weight: bold;
				display: inline-block;
				p{
					margin-bottom: 0;
				}
				@media screen and (max-width: 500px){
					display: none;

				}
			}
			.content{
				float: right;

				@media screen and (max-width: 500px){
					float: left;
					margin-left: 15px;
				}

				ul{
					li{
						margin-left: 35px;
						float: left;
						display: block;


					}
					@media screen and (max-width: 965px){
						margin-bottom: 25px;
						float: right;

						li{

							display: block;
							clear: right;
							float: right;
							margin-bottom: 3px;
						}
					}

					@media screen and (max-width: 500px){
						float: left;

						li{
							float: left;
							margin-left: 0;
							margin-right: 25px;
							.fa{
								font-size: 2em;
							}
						}

					}
				}

			}
		}

		#main-header{
			padding: $spacing-reduced-spacer;
			background: #fff;
			// overflow: hidden;
			float: left;
			width: 100%;
			position: relative;


			.hdr-logo{
				max-width: 186px;
				float: left;

			}

			#nav-main{
				float: right;
			}

			@media screen and (max-width: 855px){

				#nav-main{
					padding-top: 20px;
					float: left;

					ul{
						li{
							margin-right: 25px;
							margin-left: 0;
						}
					}

				}
			}

		}


	}
}
section#content{
	@include container-section;
}