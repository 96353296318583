#footer{
	clear:both;
}

#top-footer{
	background: $colour-accent-blue;
	padding: 25px 30px;
	overflow: hidden;



	#nav-footer{
		float: left;
		width: 50%;
		ul{
			li{
				float: left;
				margin-right: 30px;
				a{

					@include font-medium;
					color: #fff;
					transition: all 0.2s;

					&:hover{
						color: $colour-accent-orange;

					}
				}
			}
		}
	}

	#copyright{
		width: 50%;
		float: right;
		text-align: right;
		p{
			margin-bottom: 0;
			color: #fff;
			line-height: 1em;
			font-size: 0.9em;
		}
	}
	@media screen and (max-width: 880px){

		#nav-footer, #copyright{
			width: 100%;
			float: left;
			text-align: left;

		}

		#nav-footer{
			ul{
				li{
					margin-bottom: 15px;
				}
			}
		}
	}

	@media screen and (max-width: 490px){
		#nav-footer{
			ul{
				li{
					margin-right: 15px;

				}
			}
		}

	}
}
#bottom-footer{
	padding: 45px 50px 40px;
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: cover;
	.left{
		float: left;
		width: 66.66%;
		.footer-link{

			float: left;
			width: 50%;
			margin-bottom: 45px;

			ul{
				li{
					margin-bottom: 8px;
					a{
						color: #fff;

						.fa{
							color: $colour-accent-blue-pale;
						}

						&:hover{
							color: $colour-accent-orange;
							.fa{
								color: #fff;
							}
						}
					}
				}
			}
		}

	}

	.right{
		float: right;
		width: 33.33%;
		.fa{



		}
		.contact-details{
			float: left;
			width: auto;
			ul{
				li{
					color: #fff;
					margin-bottom: 10px;

					span{
						width: 19px;
						display: inline-block;
						color: $colour-accent-blue-pale;


					}
				}
			}
		}

	}

	@media screen and (max-width: 800px){
		.left, .right{
			width: 100%;
		}
	}

	@media screen and (max-width: 540px){
		.left{
			.footer-link{
				width: 100%;
			}
		}
	}
	@media screen and (max-width: 510px){
		padding: 45px 25px;
	}
}
