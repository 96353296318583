$spacing-default-vertical-margin: 30px;
$spacing-larger-vertical-margin: 45px;
$spacing-reduced-spacer: 25px;

@mixin default-bottom-margin{
	margin-bottom: $spacing-default-vertical-margin;
};




$spacing-tiny: 5px;
$spacing-very-small: 10px;
$spacing-smallish: 17.5px;
$spacing-small: 25px;
$spacing-medium: 50px;
$spacing-large: 100px;


.row{
	@include default-bottom-margin;
}