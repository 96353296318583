@mixin container-section{
	margin: 0 auto;
	max-width: $wrapper-width;
}

@mixin page-banner{
	position: relative;
	float: left;


	#banner-text{
		position: absolute;
		top: 18%;
		width: 100%;
		text-align: center;
		margin-bottom: 0;

		.breadcrumbs{
			margin-bottom: 15px;
			color: #fff;
			a{
				color: #fff;

				&:hover{
					color: $colour-accent-orange;
				}
			}
		}

		@media screen and (max-width: 970px){
			top:20%;
			h1{
				width: 95%;
			}
		}
		@media screen and (max-width: 360px){
			#home-caret{
				padding-top: 0 !important;
			}
		}

		@media screen and (max-width: 760px){
			h1{
				font-size: 2.6em;
			}
		}
		@media screen and (max-width: 650px){
			h1{
				font-size: 1.9em;
			}
			#home-caret{
				.fa{
					font-size: 3em;
				}
			}
		}

		@media screen and (max-width: 440px){
			h1{
				font-size: 1.2em;
			}
			#home-caret{
				.fa{
					font-size: 2.5em;
				}
			}
		}
		@media screen and (max-width: 350px){
			.breadcrumbs{
				margin-bottom: 5px;
			}
		}

	}
}

#page-banner{
	@include page-banner;

	#home-caret{
		transition: all 1s;
		color: #fff;
		// padding-top: 20px;
		padding-top: 0;
	}

}

@mixin ff-icon-link{
	.ff-icon-wrapper{
		margin-left: 5px;
		display: inline-block;
		.fa{
			margin: 0;
			color: $colour-accent-orange;
		}
	}
}

@mixin lozengeButton{
// arrows icons added automatically with jquery in main.js
@include ff-icon-link;

background: #fff;
border-radius: 20px;
padding: 10px 20px;
color: $colour-base-text;
transition: all 0.2s;
display: inline-block;
@include font-medium;

&:hover{
	color: $colour-accent-orange;
	background: #C4C4C4;
}

}
.lozengeButton{
	@include lozengeButton;
}
.ff-icon{
	@include ff-icon-link;
}

@mixin circleIcon{
	border: 3px solid $colour-body;
	border-radius: 45px;
	text-align: center;
	display: flex;
	align-items: center;
	float: left;
	min-width: 90px;
	min-height: 90px;
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		/* IE10+ CSS styles go here */
		min-width: none;
		min-height: none;
		width: 90px;
		height: 90px;
	}
	margin-right: 30px;
	transition: border-color 0.2s;

	img{
		width: 50%;
		margin: 0 auto;
	}

}

@mixin contactIcons{
	@include circleIcon;
	color: $colour-accent-orange;
	display: flex;
	align-items: center;
	transition: all 0.2s;
	margin-right: 110px;
	margin-bottom: 60px;

	.fa{
		margin: 0 auto
	}

	&.phone{
		.fa{
			padding-top: 5px;
		}
	}

	&:hover{
		color: $colour-accent-blue;
		border-color: $colour-accent-orange;
	}
}

@mixin newsDateIcon{
	width: 10%;
	float: left;
	display: flex;
	align-items: center;
	width: 40px;
	height: 40px;
	background: $colour-accent-blue;
	border-radius: 5px;
	text-align: center;

	>span{
		text-align: center;
		line-height: 1.2em;
		margin-bottom: 0;
		width: 100%;
		@include font-regular;
		font-weight: bold;
		font-size: 14px;
		color: #fff;
	}
}