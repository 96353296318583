#service-columns{
	display: flex;
	justify-content: space-between;
	width: 100%;
	float: left;

	.service-col-container{
		width: 23.3%;
		float: left;

		a.service-col-inner{

			background-color: $colour-accent-blue-pale;
			overflow: hidden;
			display: block;
			color: #fff;
			transition: background-color 0.3s;

			&:hover{
				color: #fff;
				background-color: $colour-accent-orange;

				.image-container{
					opacity: 0.7;
				}
			}
			.image-container{
				overflow: hidden;
				transition: opacity 0.2s;
			}
			.text{

				padding: 20px 25px;

				h2{
				}
				.services-text{
					margin-bottom: $spacing-default-vertical-margin;
				}
				.lozengeButton{
					@include lozengeButton;
					width: 100%;
					padding: 10px 0;
					text-align: center;

				}
			}
		}

	}
	@media screen and (max-width: 1095px){
		margin-bottom: 0;
		.service-col-container{
			width: 50%;
			margin-bottom: 30px;

			a{
				.image-container{
					height: 300px;

					@media screen and (max-width: 610px){
						height: 250px;
					}

				}
			}

			&:nth-child(odd){
				a{
					margin-right: 10px;
				}
			}
			&:nth-child(even){
				a{
					margin-left: 10px;
				}
			}
		}
		display: block;
	}

	@media screen and (max-width: 560px){
		.service-col-container{
			width: 100%;

			a{
				margin-left: 0 !important;
				margin-right: 0 !important;
			}
		}
	}
}

#intro{
	padding: 60px 8.3% 35px;
	background: #fff;
	float: left;


	#intro-logo{
		width: 186px;
		margin-bottom: $spacing-larger-vertical-margin;
	}
	#intro-heading{
		margin-bottom: $spacing-larger-vertical-margin;
	}
	#intro-text{

	}

	@media screen and (max-width: 780px){
		padding: 30px;
	}
}

#authority-images{
	background: #fff;
	overflow: hidden;

	display: flex;
	justify-content: space-between;
	padding: 0 30px;

	.authority-image-container{
		width: 31.73%;
		background-repeat: no-repeat;
		background-size: cover;
		text-align: center;
		float: left;
		padding: 40px 0px 10px;
		// height: 285px;

		h3{
			color: #fff;
		}
		p{
			img{
				width: 23%;
			}
		}


	}
	@media screen and (max-width: 790px){
		display: block;

		@media screen and (max-width: 450px){
			padding-left: 0px;
			padding-right: 0px;
			float: left;

		}
		.authority-image-container{
			width: 100%;
			display: flex;
			align-items: center;
			padding-left: 25px;
			padding-right: 25px;
			margin-bottom: 30px;
			text-align: left;

			@media screen and (max-width: 450px){
				padding-left: 15px;
				padding-right: 15px;
			}

			p{
				display: inline-block;
				float: left;
				min-width: 130px;



				img{
					width: auto;
					height: 100px;
				}
				@media screen and (max-width: 450px){
					min-width: 90px;

					img{
						height: 80px;
					}
				}
			}

			h3{
				display: inline-block;
				float: left;

				@media screen and (max-width: 450px){
					font-size: 1.5em;
				}

			}
		}
	}
}

#sector-links{
	background: #fff;
	padding: 95px 100px 0;
	overflow: hidden;


	h3{
		//margin-bottom: $spacing-default-vertical-margin;
	}
	@media screen and (max-width: 930px){
		h3{
			text-align: center;

		}
	}
	@media screen and (max-width: 700px){
		padding: 95px 25px 0;
	}

	#sector-link-wrapper{
		a.sector-link{
			width: 33.33%;
			float: left;
			padding: 0 20px 0 0;
			margin-bottom: 60px;
			display: flex;
			align-items: center;

			.sector-link-icon{
				@include circleIcon;
			}
			.sector-link-text{
				float: left;
				width: 50%;

			}
			&:hover{
				.sector-link-icon{
					border-color: $colour-accent-orange;
				}

			}

			@media screen and (max-width: 930px){
				flex-direction: column;
				justify-content: center;
				.sector-link-icon{
					margin-right: 0;
					margin-bottom: 15px;

				}
				.sector-link-text{
					width: 100%;
					text-align: center;

				}

				&:nth-child(3n){
					padding-right: 0;
				}
				&:nth-child(4n){
					clear: left;
				}
			}

			@media screen and (max-width: 480px){
				width: 50%;
				&:nth-child(3n){
					padding-right: 20px;
				}
				&:nth-child(4n){
					clear: none;
				}
				&:nth-child(2n){
					padding-right: 0;
				}
				&:nth-child(3n){
					clear: left;
				}

			}
		}
	}
}

#news-feed-container{
	background: #fff;
	overflow: hidden;
	float: left;


	#case-study-container{
		background: $colour-drk-grey;
		width: 66.66%;
		float: left;


		#case-study-text{
			width: 50%;
			padding: 45px 50px 15px;
			float: left;
			h3{
				//margin-bottom: $spacing-default-vertical-margin;
			}
			a.lozengeButton{
				margin-top: 4px;
			}

		}
		#case-study-image{
			width: 50%;
			position: relative;
			float: left;
			max-height: 340px;

			#case-study-arrow{
				position: absolute;
				top: 42%;
				left: -4px;
				color: $colour-drk-grey;
			}


		}

		@media screen and (max-width: 1070px){
			width: 100%;
			#case-study-image{
				overflow: hidden;

				@media screen and (max-width: 680px){
					padding: 30px;
				}

				#case-study-arrow{
					top: 42%;
				}

			}
		}

		@media screen and (max-width: 790px){
			#case-study-text{
				padding: 25px 25px 15px;
			}

		}

		@media screen and (max-width: 500px){
			padding-bottom: 30px;
			#case-study-text{
				width: 100%;
			}
			#case-study-image{
				width: 100%;
			}

		}
		@media screen and (max-width: 370px){
			padding-bottom: 0;
		}
	}
	#news-container{
		padding: 55px 50px 15px;
		width: 33.33%;
		float: right;

		h3{
			//margin-bottom: $spacing-default-vertical-margin;
		}

		.news-item{
			clear: left;
			margin-bottom: 25px;
			overflow: hidden;
			display: flex;
			align-items: center;

			.news-date{
				@include newsDateIcon;

			}
			.news-text{
				float: left;
				width: 90%;
				padding-left: 2%;
			}
		}

		@media screen and (max-width: 1070px){
			width: 100%;
		}
	}
}

#get-in-touch{
	padding: 95px 100px 0;
	background: #fff;
	overflow: hidden;
	h3{
		margin-bottom: 10px;
	}
	p{
		margin-bottom: 35px;
	}

	.contact-icons{
		@include contactIcons;

	}
	@media screen and (max-width: 810px){

		padding: 55px 50px 15px;
		#link-wrapper{
			display: flex;
			justify-content: space-around;
			.contact-icons{
				margin-right: 0;
			}
		}
	}

	@media screen and (max-width: 390px){
		padding: 25px;
	}
}