h1
{
font-size: 48px;

	@include breakpoint($max-w-initial)
	{
	// font-size: 52px;
	}

	@include breakpoint($max-w-large)
	{
	// font-size: 44px;
	}

	@include breakpoint($max-w-medium)
	{
	// font-size: 36px;
	}
}

#banner-text h1{
	color: #fff;
	width: 80%;
	display: inline-block;
}