
@font-face {
    font-family: 'robotomedium';
    src: url('../fonts/roboto/roboto-medium-webfont.eot');
    src: url('../fonts/roboto/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto/roboto-medium-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-medium-webfont.woff') format('woff'),
         url('../fonts/roboto/roboto-medium-webfont.ttf') format('truetype'),
         url('../fonts/roboto/roboto-medium-webfont.svg#robotomedium') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'robotoregular';
    src: url('../fonts/roboto/roboto-regular-webfont.eot');
    src: url('../fonts/roboto/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto/roboto-regular-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-regular-webfont.woff') format('woff'),
         url('../fonts/roboto/roboto-regular-webfont.ttf') format('truetype'),
         url('../fonts/roboto/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotolight';
    src: url('../fonts/roboto/roboto-light-webfont.eot');
    src: url('../fonts/roboto/roboto-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto/roboto-light-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-light-webfont.woff') format('woff'),
         url('../fonts/roboto/roboto-light-webfont.ttf') format('truetype'),
         url('../fonts/roboto/roboto-light-webfont.svg#robotolight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@mixin font-light
{
font-family: 'robotolight', Arial;
}

@mixin font-regular
{
font-family: 'robotoregular', Arial;
font-weight: 300;
}

@mixin font-medium
{
font-family: 'robotomedium', Arial;
font-weight: 400;
}

@mixin font-bold
{
font-family: 'Roboto', Arial;
font-weight: 500;
}

@mixin font-serif-medium
{
font-family: 'Merriweather';
font-weight: 400;
}

@mixin font-serif-light
{
font-family: 'Merriweather';
font-weight: 300;
}


