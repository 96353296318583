h2
{
font-size: 28px;
margin-bottom: $spacing-default-vertical-margin;

@include font-light;

	@include breakpoint($max-w-initial)
	{
	// font-size: 36px;
	}

	@include breakpoint($max-w-medium)
	{
	// font-size: 30px;
	}
}