$colour-body: #e8e8e8; //body grey colour
$colour-base-text: #000; //base text colour
$colour-accent-blue-pale: #00aff3;
$colour-accent-blue: #009fee;
$colour-accent-orange: #ff5e00;
$colour-drk-grey: #bebebe;



$colour-primary: #000;


$colour-dark-grey: #333434;
$colour-grey: #808080;
$colour-medium-grey: #e8e7e7;
$colour-light-grey: #f5f5f5;
$colour-dd-grey: #F9F9F9;


$colour-facebook: #3b5998;
$colour-twitter: #55acee;
$colour-linkedin: #007bb5;