.inline-input
{
	input, textarea
	{
	background: $colour-light-grey;
	border: 0;
	padding: $spacing-very-small;
	font-size: 12px;
	resize: vertical;

	@include font-medium;

		&:focus
		{
		outline: none;
		}
	}

	&--50
	{
	@extend .inline-input;
	
	width: 47.5%;
	}
}