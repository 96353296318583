#company-wrapper{


	#company-top, #company-bottom{
		float: left;
		overflow: hidden;

		background: #fff;
		.left, .right{
			width: 50%;
			float: left;

			ul{
				margin-left: 15px;
				li{
					margin-bottom: 15px;
					&:before{
						content: "- ";
						margin-left: -15px;
					}
					a{
						@include ff-icon-link;
					}
				}
			}
		}
	}
	#company-top .left, #company-bottom .right{
		padding: 55px 50px 15px;

		@media screen and (max-width: 870px){
			width: 70%;
		}

		@media screen and (max-width: 550px){
			width: 100%;
			padding: 55px 25px 25px;
		}
	}


	#company-top .right, #company-bottom .left{
		max-height: 600px;
		img{
			display: block;
		}

		@media screen and (max-width: 1030px){
			padding: 55px 25px;
		}
		@media screen and (max-width: 870px){
			width: 30%;
		}
		@media screen and (max-width: 550px){
			width: 100%;
		}
	}

	#company-top .right{
		padding-left: 0;
	}

	#company-bottom .left{
		padding-right: 0;
	}
	@media screen and (max-width: 550px){
		#company-top .right, #company-bottom .left{
			padding: 25px;
		}
	}
}

#case-studies-wrapper{

	.case-study-container{
		background: #fff;
		float: left;


		.left, .right{
			width: 50%;
			float: left;

		}
		.left{
			padding: 55px 50px 15px;
		}
		.right{
			max-height: 600px;
			position: relative;
			img{
				max-height: 600px;
			}
			#case-study-arrow{
				position: absolute;
				top: 42%;
				left: -4px;
				color: #fff;
			}
		}

		&:nth-child(2n){
			background: #67b7df;

			.right{

				#case-study-arrow{
					color: #67b7df
				}
			}
		}

		&:nth-child(3n){
			background: #ff8e48;

			.right{

				#case-study-arrow{
					color: #ff8e48;
				}
			}
		}

		@media screen and (max-width: 1140px){
			.left{
				width: 75%;
			}
			.right{
				width: 25%;
				padding-top: 55px;
				padding-right: 25px;
			}
		}
		@media screen and (max-width: 550px){
			.left, .right{
				width: 100%;

			}

			.right{
				padding: 25px;
			}
		}

	}
}

#news-slogan{
	text-align: center;
	float: left;
	width: 100%;
	margin-bottom: 60px;
	margin-top: 22px;

	.dropdown-wrapper{
		display: inline-block;
		position: relative;

		button{
			background: #fff;
			border-radius: 20px;
		}

		ul{
			width: 100%;

			li{
				border-bottom: 1px solid $colour-body;
				&:last-child{
					border-bottom: none;;
				}
				a{
					&:hover{
						color: $colour-body;
						background: #fff;
					}
				}
			}
		}
	}

}

#news-archive-wrapper{

	.news-container{
		background: #fff;
		float: left;
		width: 100%;

		.left{
			width: 33.33%;
			float: left;
			max-height: 340px;
			overflow: hidden;


			img{
				height: 100%;
				width: auto;

			}
		}
		.right{
			width: 66.66%;
			float: left;
			padding: 55px 40px 10px;

			.post-text{
				.post-date{
					float: left;
					margin-right: 20px;
					margin-bottom: 20px;
					@include newsDateIcon;



				}

				a{
					margin-top: 10px;
					border: 3px solid $colour-body;
					&:hover{
						text-decoration: none;
					}
				}
			}


		}
		@media screen and (max-width: 670px){
			.left{
				height: 170px;
				width: 170px;
				padding: 25px 0 25px 25px;
				img{
					width: 100%;
					height: auto;
				}
			}
		}
		@media screen and (max-width: 510px){
			.right{
				width: 100%;
				padding: 25px;
			}
		}

	}
}

#news-post-wrapper{
	background: #fff;
	padding: 45px 50px;
	float: left;
	width: 100%;
	a{
		color: $colour-accent-blue;

		&:hover{
			color: $colour-accent-orange;
		}
	}

	.left{
		width: 35%;
		float: left;

		img{
			margin-bottom: 55px;
		}

		.excerpt{
			.post-date{
				float: left;
				margin-right: 20px;
				margin-bottom: 400px;
				@include newsDateIcon;

				@media screen and (max-width: 620px){
					margin-bottom: 50px;
				}

				@media screen and (max-width: 370px){
					margin-bottom: 90px;
				}

			}
			p{
				@include font-medium;
			}
		}

	}
	.right{
		width: 65%;
		float: left;

		.inner{
			margin-left: 50px;
		}
	}

	@media screen and (max-width: 620px){
		.left, .right{
			width: 100%;
		}
		.right{
			.inner{
				margin-left: 0;
			}
		}
	}
	@media screen and (max-width: 510px){
		padding: 25px;
	}
}

#sub-heading{
	float: left;
	width: 100%;
	text-align: center;
	padding: 20px 0;
}

#contact-intro{
	background: #fff;
	float: left;
	width: 100%;

	.left{
		float: left;
		width: 75%;
		padding: 55px 50px 20px;
	}
	.right{
		float: left;
		width: 25%;
		img{
			display: block;
		}

		@media screen and (max-width: 1120px){
			padding: 25px 25px 25px 0;
		}

	}

	@media screen and (max-width: 620px){
		.left, .right{
			width: 100%;
		}

		.right{
			padding: 0 100px 25px;
		}
	}

	@media screen and (max-width: 510px){
		.left{
			padding: 25px;
		}
	}
}

#contact-wrapper{
	float: left;
	width: 100%;
	background: #fff;
	padding: 55px 50px 20px;
	overflow: hidden;

	.left{
		width: 55%;
		float: left;


		.contact-info-container{
			float: left;
			clear: left;
			display: flex;
			align-items: center;
			margin-bottom: 25px;
			.contact-icons{
				@include contactIcons;

				float: left;
				margin-right: 25px;
				margin-bottom: 0;

				&:hover{
					border-color: $colour-body;
					.fa{
						color: $colour-accent-orange;
					}
				}
			}
			.contact-icons-spacer{
				width: 90px;
				display: inline-block;

			}
			.contact-info{
				float: left;
				h3{
					margin-bottom: 3px;
					color: #464646;
					display: inline-block;
					line-height: 1.2em;
				}

				&.address{
					h3{

						padding-top: 30px;
					}
				}

			}
		}
	}
	.right{
		width: 45%;
		float: left;

		.icon-wrapper{
			width: 25%;
			float: left;


			.contact-icons{
				@include contactIcons;

				float: left;
				margin-right: 5%;
				margin-bottom: 0;

				&:hover{
					border-color: $colour-body;
					.fa{
						color: $colour-accent-orange;
					}
				}
			}
		}

	}
	@media screen and (max-width: 1055px){
		.left, .right{
			width: 100%;
		}

		.right{
			.icon-wrapper{
				width: auto;
				.contact-icons{
					margin-right: 25px;
				}

			}
			.form{
				margin-bottom: 55px;
			}
		}
	}

	@media screen and (max-width: 630px){
		.left{
			text-align: center;
			.contact-info-container{

				display: flex;
				flex-direction: column;
				text-align: center;
				justify-content: center;
				width: 100%;


				.contact-icons{
					margin-right: 0;
				}
				.contact-info{
					width: 100%;
					clear: left;
					display: block;
					h3{
						word-wrap: break-word;
						word-break: break-word;
					}

				}

				.address{
					ul{
						text-align: center;
						li{

							display: flex;
							flex-direction: column;
							justify-content: center;
							width: auto;



							span{
								max-width: 90px;
								float: none;
								margin: 0 auto;
							}

						}
					}
				}


			}

		}

		.right{
			.icon-wrapper{
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.contact-icons{
					max-width: 90px;
					float: none;
					margin: 0 auto;
				}
			}
			.form{
				width: 100%;
			}
		}
	}

	@media screen and (max-width: 510px){
		padding: 25px;
	}

}

#business-cards{
	float: left;

	.left, .right{
		float: left;
		width: 50%;

		.inner{
			background: #fff;
			overflow: hidden;
			padding: 55px 50px;

			.name{
				@include font-medium;
				margin-bottom: 25px;

			}
			.text{
				margin-bottom: 20px;
			}
			.number{
				display: flex;
				align-items: center;
				.contact-icons{
					@include contactIcons;

					float: left;
					margin-right: 5%;
					margin-bottom: 0;

					&:hover{
						border-color: $colour-body;
						.fa{
							color: $colour-accent-orange;
						}
					}
				}
				.phone{
					h3{
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.left{
		.inner{
			margin-right: 15px;
		}
	}

	.right{
		.inner{
			margin-left: 15px;
		}
	}

	@media screen and (max-width: 610px){
		.left, .right{
			width: 100%;
			.inner{
				margin: 15px;
			}
		}
	}
}


//SECTORS/SERVICES

#headlines-wrapper{
	float: left;
	width: 100%;
	padding: 21px 100px 21px;
	ul{
		li{
			clear: left;

			.fa{
				font-size: 1.3em;
				color: $colour-accent-orange;
				float: left;
				width: 4%;
				line-height: 1.6em;

				@media screen and (max-width: 430px){
					width: 6%;
				}

			}
			p{
				font-size: 1.3em;
				@include font-light;
				display: inline-block;
				float: left;
				width: 95%;

				@media screen and (max-width: 565px){
					width: 92%;
				}
			}
		}
	}
	@media screen and (max-width: 860px){
		padding: 15px 30px;
	}


}

#sector-content{
	background: #fff;
	float: left;
	width: 100%;

	.left{
		padding: 55px 50px;
		float: left;
		width: 50%;

		ul{
			margin-left: 15px;
			li{
				line-height: 1.3em;
				margin-bottom: 15px;
				&:before{
					margin-left: -15px;
					content: "- ";
				}
				a{
					@include ff-icon-link;
				}
			}
		}

	}
	.right{
		float: right;
		width: 50%;
		overflow: hidden;

		#main-image{
			float: left;
			overflow: hidden;
			position: relative;
			margin-bottom:7px;
			padding-left:7px;


			img{
				display: block;

				height: 100%;
				width: auto;
			}

		}
		#smaller-images-wrapper{
			width: 100%;
			float: left;

			#smaller-images-wrapper-inner{
				float: left;
				width: 100%;
				margin-left: 7px;
				overflow: hidden;
				div{
					// width: 100%;

					margin-left: 7px;
					margin-bottom: 7px;

					width: calc(50% - #{7px*1/2}) !important; // 2 items per line

					&:first-child{
						margin-left: 0;
					}
					&:nth-child(2n+1) {
						margin-left: 0;
					}
					&:nth-child(-n+2) {
						margin-top: 0;
					}


					// width: 48%;
					float: left;
					max-height: 280px;
					overflow: hidden;
					border: none;
					// margin-bottom: 7px;
					// margin-right: 7px;

					height: 280px;
					background-size: cover !important;

					&:nth-child('even'){
						margin-right: 0;
					}
					img{
						width: 100%;
						display: block;
						border: none;
					}
				}
			}
		}

		@media screen and (max-width: 705px){
			padding: 55px 0px 25px 25px;
		}

	}

	@media screen and (max-width: 550px){
		.left, .right{
			width: 100%;

		}
		.right{
			padding-left: 0;
			#main-image{
				padding-left: 0;
			}

			#smaller-images-wrapper{


				#smaller-images-wrapper-inner{

					margin-left: 0;
				}
			}
		}

	}
}


#page404{
	background: #fff;
	padding: 55px 50px;
	float: left;
	width: 100%;
}

#contact-wrapper {
	.form {
		float: left;
		width: 75%;
	}
}

#contact-wrapper, .contact-form-wrapper {
	form{
		input, textarea{
			background: #dedede;
			border: none;
			border-radius: 3px;
			padding: 15px 20px 10px;
		}

		input[type="submit"]{
			background: $colour-accent-blue;
			padding-bottom: 15px;
			transition: all 0.2s;
			text-shadow: none;

			&:hover{
				background: $colour-accent-orange;
				color: #464646;
			}
		}
		.form-submit{
			height: 50px;
			margin-bottom: 30px;
		}
	}
}

div.wpcf7 img.ajax-loader {
	width: 25px;
}

/* page css */

.page-wrapper {
	width: 100%;
	background: white;
	padding: 55px 50px 20px;
	float: left;
	margin-bottom: 30px;
}