#nav-main{
	padding-top: 87px;

	ul{
		> li{
			float: left;
			margin-left: $spacing-reduced-spacer;
			> a{
				@include font-medium;
				font-size: 1.14em;
				transition: border 0.2s;

				&:hover{
					border-bottom: 3px solid $colour-accent-blue;
					color: $colour-base-text;
				}

			}
			> ul{
				display: none;
				>li{

					>a{

					}
				}

			}
		}
	}

	//hover menus
	ul{
		> li{
			&.menu-item-has-children{
				position: relative;
				&:hover{
					> ul{
						display: block;
						position: absolute;
						top: 100%;
						left: -13%;
						z-index: 100;
						padding-top: 15px;

						background-color: #fff;
						-webkit-box-shadow: 0 10px 6px 2px rgba(1,1,1,0.3);
						box-shadow: 0 10px 6px 2px rgba(1,1,1,0.3);


						width: 225px;

						li{
							margin-left: 0;
							width: 100%;
							a{
								padding: 15px;
								display: inline-block;
								transition: all 0.2s;
								.fa{
									transition: all 0.2s;
									color: $colour-body;
								}

								&:hover{
									color: #ff5e00;
									border: none;
									.fa{
										color: $colour-accent-orange;
									}
								}
							}
							border-top: 1px solid $colour-body;
						}
					}
				}
			}

		}
	}
}
#menu-trigger{
	color: $colour-accent-blue;
	float: right;
	margin-top: 77px;
	display: none;
	tranition: all 0.2s;

	&:hover{
		color: $colour-accent-orange;
	}
	&.active{
		color: $colour-accent-orange;
	}
}

//at 670px wide - trigger mobile menu
@media screen and (max-width: 670px){
	#menu-trigger{
		display: block;
	}

	#nav-main{
		display: none;
	}
}

#nav-mobile{
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	display: none;
	z-index: 300;
	-webkit-box-shadow: 0 5px 3px 0 rgba(1,1,1,0.3);
	box-shadow: 0 5px 3px 0 rgba(1,1,1,0.3);


	ul{

		width: 100%;
		> li{

			background: #fff;
			width: 100%;
			float: left;
			display: block;
			border-bottom: 1px solid $colour-body;

			> a{
				@include font-medium;

				padding: 10px 25px;
				font-size: 1.5em;
				display: block;


			}

			.mobile-sub-trigger{
				float: right;
				color: $colour-accent-blue;
				padding-top: 9px;
				padding-right: 31px;

				&.active{
					color: $colour-accent-orange;
				}
			}

			> ul{
				display: none;
				>li{
					>a{
					}
				}

			}
		}
	}

	//hover menus
	ul{
		> li{
			&.menu-item-has-children{
				> ul{


					width: 100%;

					li{
						margin-left: 0;
						width: 100%;
						a{
							padding: 15px;
							padding-left: 40px;

						}
					}
				}
			}
		}
	}
}

